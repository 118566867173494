.blurContainer {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

.blurContainer:hover {
  cursor: pointer;
}

.blurContent {
  filter: blur(5px);
  user-select: none;
}

.hoverMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.8em;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
}

.blurContainer:hover .hoverMessage {
  opacity: 1;
}