.filterContainer {
  position: relative;
  width: 100%;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
  overflow: hidden;
}

.mobile {
  max-height: none;
  overflow: visible;
}

.filterContainer:not(.mobile) {
  max-height: 150px;
}

.filterContainer:not(.mobile):hover,
.filterContainer:not(.mobile).expanded {
  max-height: 600px;
}

.filterContainer:not(.mobile)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.filterContainer:not(.mobile):hover::after,
.filterContainer:not(.mobile).expanded::after {
  opacity: 0;
}

.filterContainer > * {
  transition: opacity 0.4s ease-in-out;
}

.filterContainer button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: 0.5rem;
}

.mobile button {
  display: inline-block;
}