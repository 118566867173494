.filterContainer {
  position: relative;
  width: 100%;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
  overflow: hidden;
}

.mobile {
  max-height: none;
  overflow: visible;
}

.filterContainer:not(.mobile) {
  max-height: 150px; /* Adjust this value based on the approximate height of 5 filters */
}

.filterContainer:not(.mobile):hover,
.filterContainer:not(.mobile).expanded {
  max-height: 600px; /* Adjust this value based on the maximum expected height of all filters */
}

/* Add a fade effect for the last visible filter on desktop */
.filterContainer:not(.mobile)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.filterContainer:not(.mobile):hover::after,
.filterContainer:not(.mobile).expanded::after {
  opacity: 0;
}

/* Add smooth transition for child elements */
.filterContainer:not(.mobile) > * {
  transition: opacity 0.4s ease-in-out;
}

/* Ensure smooth appearance of new elements on desktop */
.filterContainer:not(.mobile):not(.expanded) > *:nth-child(n+6) {
  opacity: 0;
}

.filterContainer:not(.mobile).expanded > *,
.filterContainer:not(.mobile):hover > * {
  opacity: 1;
}

/* Style for the "Show All" link */
.filterContainer button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: 0.5rem;
}

.mobile button {
  display: inline-block;
}